<template src="./index.html" />

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BModal, BRow, BOverlay, BFormValidFeedback, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BModal,
    BCard,
    ToastificationContent,
    BOverlay,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      showFormVerify: false,
      email: '',
      newPassword: '',
      inputValue: ',',
      code: '',
      password: '',
      rePassword: '',
    }
  },
  computed: {
    // calculate data from init data
    stateEmail() {
      return this.checkEmail(this.email)
    },
    statePassword() {
      return this.CheckPassword(this.password)
    },
    stateRePassword() {
      return !!(this.password === this.rePassword && this.CheckPassword(this.rePassword))
    },
    stateCode() {
      return !!(this.code && this.code.length === 6)
    },

    stateVerify() {
      return !!(this.code && this.code.length === 6 && this.CheckPassword(this.password) && this.password === this.rePassword)
    },

  },
  watch: {
    // listening change data init

  },

  created() {
    // init data FROM API
  },
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword',
      resetPassword: 'auth/resetPassword',
      verifyCode: 'auth/verifyPassword',
    }),
    // handle even
    async handleForgotPassword() {
      this.isLoading = true
      try {
        const res = await this.forgotPassword({ email: this.email })
        if (res.isSuccessful) {
          this.showFormVerify = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gửi mã xác thực qua email thành công',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.isLoading = false
    },

    async verifyForgotPassword() {
      this.isLoading = true
      try {
        const result = await this.verifyCode(({ email: this.email, code: this.code }))
        if (result.isSuccessful) {
          const res = await this.resetPassword({
            email: this.email, type: 0, password: this.password, code: this.code,
          })
          if (res.isSuccessful) {
            this.showFormVerify = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thay đổi mật khẩu thành công',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await router.push({ path: '/auth/login' })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
      this.isLoading = false
    },

    async sendConfirm() {

    },

    checkEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true
      }
      return false
    },

    CheckPassword(input) {
      const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
      return !!input.match(decimal)
    },
  },
}
</script>

<style scoped>

</style>
